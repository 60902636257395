export const skillWords = [
  {'text': 'JavaScript', 'value': 40.817419155172445},
  {'text': 'React', 'value': 34.17686878677724},
  {'text': 'React Native', 'value': 32.38432567882438},
  {'text': 'Angular 2+', 'value': 26.033273005554115},
  {'text': 'NodeJs', 'value': 24.940980447649473},
  {'text': 'Redux', 'value': 23.841054294183248},
  {'text': 'PHP', 'value': 23.677088109961158},
  {'text': 'Express', 'value': 23.43331000135826},
  {'text': 'MongoDB', 'value': 21.021191275530892},
  {'text': 'SQL', 'value': 20.401567836989976},
];
